import React from 'react';
import * as yup from 'yup';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Form, Formik, type FormikConfig } from 'formik';
import { type CreateProject, type GetOneProject } from '../../../api/repository/types/project.type';
import ProjectRepository from '../../../api/repository/ProjectRepository';
import { notify } from '../../../services/notification/notification';
import ImageEditableField from '../../../components/form/fields/image.editable.field';
import SelectField from '../../../components/form/fields/select.field';
import { closeReasons, states, templates, visibleOnOptions } from '../services/constants';
import InputField from '../../../components/form/fields/input.field';
import BooleanField from '../../../components/form/fields/boolean.field';
import UserAutocomplete from '../../../components/autocomplete/user.autocomplete';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import TagsField from '../../../components/form/fields/tags.field';
import { generateBaseProps, generateOptionsItemSelected } from '../../../components/form/fields/services/select.service';
import { useProjectParents } from '../../../api/hooks/project.hook';
import './project.form.scss';
import TranslatableField from '../../../components/form/fields/translatable.field';
import Helper from '../../../components/form/fields/transverse/helper';

export interface ProjectBaseFormProps {
  onSubmit: FormikConfig<CreateProject>['onSubmit'];
  initialProject?: Partial<GetOneProject>;
  status?: 'new' | 'edit';
}

const ProjectBaseForm: React.FC<ProjectBaseFormProps> = ({ initialProject, onSubmit, status }) => {
  const [parents] = useProjectParents();

  const project: CreateProject = {
    state: 'draft',
    name: '',
    position: 1,
    closeAfterGoal: false,
    isPublic: false,
    goal: undefined,
    notificationEmails: [],
    visibleOn: [],
    close: false,
    closeReason: '',
    isParent: false,
    template: 'one_column',
    templating: {
      showAmountReachedAsPackage: false,
      amountByPackage: 0,
      packageLabels: {
        fr: '',
        en: ''
      }
    },
    ...initialProject,
    creator: initialProject?.creator?.id,
    parent: initialProject?.parent?.id,
    image: '',
    imageMobile: ''
  };

  const schema = yup.object<Partial<CreateProject>>({
    name: yup.string().required(),
    position: yup.number().required(),
    goal: yup.number().when('type', {
      is: 'collect',
      then: (schema) => schema.notRequired()
    })
  });

  const duplicate = () => {
    ProjectRepository.duplicate(initialProject!.id).then(({ data }) => {
      notify({ title: 'Dupliqué avec succès', message: 'redirection en cours...' });
      setTimeout(() => {
        window.location.href = `/projects/${data.id}/edit/informations`;
      }, 3000);
    });
  };

  const baseImage = typeof initialProject?.image !== 'string' ? initialProject?.image?.url : '';
  const baseImageMobile = typeof initialProject?.imageMobile !== 'string' ? initialProject?.imageMobile?.url : '';
  const baseUser = initialProject?.creator ? { value: initialProject.creator.id, label: initialProject.creator.email } : {};

  return (
    <Container>
      {status === 'edit' && (
        <Row>
          <Col>
            <div className="float-right">
              <Button onClick={duplicate}>Dupliquer</Button>
            </div>
          </Col>
        </Row>
      )}

      <Formik onSubmit={onSubmit} initialValues={project} validationSchema={schema} validateOnMount>
        {(formik) => {
          const { handleChange, setFieldValue, values, errors } = formik;
          const { name, position, state, image, imageMobile, goal, close, closeAfterGoal, closeReason, isParent, visibleOn, isPublic, notificationEmails, parent, template } = values;

          const baseProps = {
            onChange: handleChange,
            showError: true,
            errors: errors
          };

          return (
            <>
              <Row>
                <Col>
                  <ImageEditableField
                    helper="Image format desktop"
                    errors={errors}
                    showError
                    cropProps={{ width: 1005, height: 502 }}
                    imageProps={{ width: 1005, height: 502 }}
                    reactCropProps={{ locked: true }}
                    showPreview
                    name={'image'}
                    defaultValue={baseImage}
                    onChange={(name, value) => {
                      setFieldValue('image', value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ImageEditableField
                    helper="Image format mobile"
                    errors={errors}
                    showError
                    cropProps={{ width: 576, height: 450 }}
                    imageProps={{ width: 576, height: 450 }}
                    reactCropProps={{ locked: false }}
                    showPreview
                    name={'imageMobile'}
                    defaultValue={baseImageMobile}
                    onChange={(_, value) => {
                      setFieldValue('imageMobile', value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField {...baseProps} label={'Nom du projet*'} name={'name'} value={name} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <InputField
                    {...baseProps}
                    label="Position*"
                    type="number"
                    onChange={handleChange}
                    name={'position'}
                    value={position}
                    helper={"ordre d'affichage, plus la position est basse, plus il sera haut dans l'affichage."}
                  />
                </Col>
                <Col>
                  <SelectField label="Etat" onChange={handleChange} name={'state'} value={state} options={states} helper={"Etat de publication, seul l'état publié permet aux visiteurs de voir ce projet."} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectField
                    label="Template"
                    onChange={handleChange}
                    name={'template'}
                    value={template}
                    options={templates}
                    helper={'Lorsque 2 colonnes, objectifs et premier produits à droite (puis en bas). Sinon objectif en haut et la liste des produits en bas.'}
                  />
                </Col>
              </Row>

              <div className="bg-light py-3 px-2">
                <Row>
                  <Col>
                    <BooleanField
                      {...baseProps}
                      type="switch"
                      name="templating.showAmountReachedAsPackage"
                      defaultValue={values.templating?.showAmountReachedAsPackage}
                      label={'Afficher le montant total des donations par package ?'}
                    />
                  </Col>
                </Row>

                {values.templating?.showAmountReachedAsPackage && (
                  <>
                    <Row>
                      <Col>
                        <InputField
                          {...baseProps}
                          type="number"
                          helper="Ce nombre permettra de diviser le montant total pour definir le nombre de package"
                          label="Prix par package"
                          name="templating.amountByPackage"
                          value={values?.templating?.amountByPackage}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Helper text={`Doit contenir %d ou le montant sera place. Exemple '<h4> <strong class="text-primary">%d</strong> arbres</h4>'.`}></Helper>
                        <InputField label="Format en francais" name="templating.packageLabels.fr" defaultValue={values?.templating?.packageLabels?.fr} {...baseProps} />
                        <InputField label="Format en anglais" {...baseProps} name="templating.packageLabels.en" defaultValue={values?.templating?.packageLabels?.en} />
                      </Col>
                    </Row>

                    <hr />
                  </>
                )}
              </div>

              <hr />

              <div className="bg-light py-3 px-2">
                <Row>
                  <Col>
                    <BooleanField
                      type="switch"
                      name="noObjectif"
                      onChange={(v: any) => {
                        if (v.target.checked) {
                          setFieldValue('goal', 0);
                        } else {
                          setFieldValue('goal', undefined);
                          setFieldValue('closeAfterGoal', undefined);
                        }
                      }}
                      label={'Avec objectif'}
                      value={goal !== undefined}
                    />

                    {goal !== undefined && <InputField {...baseProps} type="number" label="Objectif" placeholder="Example: 125.00" name="goal" defaultValue={goal} />}

                    {goal !== undefined && (
                      <TagsField
                        placeholder={'email example: notif@nouvelleoptique.com'}
                        label={'Notifier à'}
                        isValidNewOption={(value) => {
                          const schema = yup.string().email();
                          return schema.isValidSync(value);
                        }}
                        {...generateBaseProps(formik, 'notificationEmails')}
                        name={'notificationEmails'}
                        helper="Lorsque l'objectif est atteint, les emails suivant seront notifiés"
                        defaultValue={generateOptionsItemSelected(notificationEmails)}
                      />
                    )}

                    {closeAfterGoal !== undefined && (
                      <BooleanField
                        {...baseProps}
                        type="switch"
                        label={'Fermer après objectif atteint'}
                        name="closeAfterGoal"
                        defaultValue={closeAfterGoal}
                        helper="Si oui, un message sera affiché une fois l'objectif atteint et les dons impossibles."
                      />
                    )}
                  </Col>
                </Row>
              </div>

              <hr />

              <div className="bg-light py-3 px-2">
                {
                  <BooleanField
                    type="switch"
                    name="close"
                    onChange={(v: any) => {
                      handleChange(v);
                      if (v.target.checked) {
                        setFieldValue('closeReason', closeReasons[0].value);
                      } else {
                        setFieldValue('closeReason', undefined);
                      }
                    }}
                    label={'Projet fermé ?'}
                    defaultValue={close}
                  />
                }

                {close && (
                  <SelectField {...baseProps} label="Raison de la fermeture" name={'closeReason'} value={closeReason} options={closeReasons} helper={"Lorsqu'un objectif est atteint, la fermeture est automatique."} />
                )}
              </div>

              <hr />

              <div className="bg-light py-3 px-2">
                {
                  <UserAutocomplete
                    label={'Affilier la création à ? '}
                    onChange={async (user: OptionItem) => await setFieldValue('creator', user.value)}
                    defaultValue={baseUser}
                    helper="Sera utilisé pour le backoffice de l'utilisateur afin d'avoir un etat de l'avancé et recevoir les photos."
                  />
                }
              </div>

              <hr />

              <TagsField
                placeholder={'Ou sera visible le projet'}
                label={'Visibilité du projet'}
                {...generateBaseProps(formik, 'visibleOn')}
                name={'visibleOn'}
                options={visibleOnOptions}
                defaultValue={generateOptionsItemSelected(visibleOn, visibleOnOptions)}
              />

              <hr />

              <BooleanField
                type="switch"
                name="isParent"
                onChange={(v: any) => {
                  handleChange(v);
                  if (v.target.checked) {
                    setFieldValue('parent', undefined);
                  }
                }}
                helper="Exemple: Le projet puis est un projet parent vu qu'il a puit ghana, etc..."
                label={'Projet parent ?'}
                defaultValue={isParent}
              />

              {!isParent && (
                <SelectField
                  {...baseProps}
                  emptyLabel="Aucun"
                  label="Quel est le projet parent"
                  name={'parent'}
                  value={parent}
                  options={parents}
                  helper="Exemple: pour un projet Puits Ghana, le projet parent sera puit"
                />
              )}

              <Form onSubmit={formik.handleSubmit}>
                <div className="text-center">
                  <Button type="submit" disabled={!formik.isValid}>
                    {status === 'edit' ? 'Editer' : 'Sauvegarder'}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ProjectBaseForm;
