import React from 'react';
import ToggleShowWrapper from '../../../../components/wrapper/toggle-show.wrapper';
import { GetRequestProjectType } from '../../../../api/repository/types/request-project.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheckSquare, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import BasicMail from '../../../../components/email/basicMail';
import Modal from '../../../../components/modal/modal';
import ProjectSelectForm from '../../../../components/global-form/project-select.form';
import RequestProjectRepository from '../../../../api/repository/RequestProjectRepository';
import { confirm } from 'react-bootstrap-confirmation';
import { notify } from '../../../../services/notification/notification';
import './cell.scss';
import StringUtil from '../../../../services/string/StringUtil';
import { getProjectName } from '../../../../services/string/ProjectUtil';

const ActionCell: React.FC<{ requestProject: GetRequestProjectType }> = ({ requestProject }) => {
  if (!requestProject) {
    return null;
  }

  const attachToProject = async (projectId: string) => {
    if (!(await confirm('Voulez-vous vraiment lié cette requête à ce projet et mettre le status à "terminé"?'))) {
      return;
    }

    RequestProjectRepository.attachProjectAndDone(requestProject.id, projectId).then(() => {
      notify({
        title: 'Votre requête a bien été relié',
        message: 'Pour voir les changements veuillez rechargé la page'
      });
    });
  };

  return (
    <>
      <Link to={`/request-projects/${requestProject.id}/edit`}>
        <FontAwesomeIcon icon={faEdit} />
      </Link>
      <ToggleShowWrapper toggleElement={<></>} toggleElementProps={{ className: 'toggle-show-wrapper d-inline-block attach-project' }}>
        {({ toggle, shown }) => (
          <>
            <FontAwesomeIcon icon={faCheckSquare} onClick={toggle} />
            <Modal show={shown} onClose={toggle} title="Sélectionner le projet auquel rattacher la demande">
              <ProjectSelectForm onSubmit={(project) => attachToProject(project.project)} />
            </Modal>
          </>
        )}
      </ToggleShowWrapper>

      <ToggleShowWrapper toggleElement={requestProject?.project ? <FontAwesomeIcon icon={faEnvelope} /> : null} toggleElementProps={{ className: 'toggle-show-wrapper d-inline-block email' }}>
        {({ toggle, shown }) => (
          <Modal show={shown} onClose={toggle} title="Envoyer l'email" modalProps={{ size: 'xl' }}>
            <BasicMail
              onSend={() => notify({ title: 'Message envoyé', message: 'Votre email a été envoyé' })}
              defaultData={{
                mail: `info@nouvelleoptique.org`,
                message: `Salaam aleikoum
<br/>Voici le lien de la collecte à partager :
<a href="${process.env.REACT_APP_API_BASE_URL}/actions-redirect/${requestProject.project?.id}">${StringUtil.removeHTMLTags(getProjectName(requestProject))}</a>
<br>Votre adresse mail pour la réception des médias:  ${requestProject.creator.email}
<br>Qu’Allah vous facilite !`,
                subject: `Demande de collecte terminée`
              }}
            />
          </Modal>
        )}
      </ToggleShowWrapper>
    </>
  );
};

export default ActionCell;
