import React, { useEffect, useState } from 'react';
import { type FormikConfig } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { pick } from 'underscore';
import { notify } from '../../../services/notification/notification';
import RequestProjectRepository from '../../../api/repository/RequestProjectRepository';

import { type CreateRequestProjectType, type GetRequestProjectType } from '../../../api/repository/types/request-project.type';
import RequestProjectForm from '../form/request-project.form';

export interface Props {
  id?: string;
}

const RequestProjectEdit: React.FC<Props> = () => {
  const [requestProject, setRequestProject] = useState<GetRequestProjectType>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    RequestProjectRepository.get(id).then((response) => {
      setRequestProject(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<CreateRequestProjectType>['onSubmit'] = (requestProject: CreateRequestProjectType) => {
    const keys: Array<keyof CreateRequestProjectType> = ['name', 'privateVisibility', 'state', 'description', 'nbPerson', 'type', 'destination', 'product'];

    RequestProjectRepository.edit(id, pick(requestProject, keys)).then(() => {
      notify({
        title: 'Demande modifiée.',
        message: 'la demande a été modifiée'
      });
    });
  };

  const schema = yup.object<Partial<GetRequestProjectType>>({
    name: yup.string().required(),
    description: yup.string().required(),
    privateVisibility: yup.boolean()
  });

  if (!requestProject) {
    return <>Chargement...</>;
  }

  return <RequestProjectForm initialValue={requestProject} onSubmit={onSubmit} validationSchema={schema} />;
};

export default RequestProjectEdit;
